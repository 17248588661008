/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "版权声明"), "\n", React.createElement(_components.p, null, "除转载者外，本网站所载文字、图像等信息的版权皆由黑龙江神顶峰黑蜂产品有限公司所有，受著作权法保护。未经许可，请勿使用。对于侵权行为，我司保留追究法律行为的权利。"), "\n", React.createElement(_components.h2, null, "商标声明"), "\n", React.createElement(_components.p, null, "“神顶峰”、“雪蜜”均为黑龙江神顶峰黑蜂产品有限公司的注册商标，受商标法保护。对于侵权行为，我司保留追究法律行为的权利。"), "\n", React.createElement(_components.h2, null, "网站说明"), "\n", React.createElement(_components.p, null, "sdfbee.cn、sdfbee.com 为黑龙江神顶峰黑蜂产品有限公司持有的域名，您可点击页尾备案号码或访问工信部域名信息备案管理系统进行查询。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
